import React from "react";
import { Field, Formik } from "formik";
import { Col, Row } from "react-bootstrap";
import { personalInfoSchema } from "../../validationSchemas/personalInfoSchema";
import MaskedInput from "react-text-mask";

interface IPhoneNumberForm {
    setPhoneNumber: (string) => void,
    isCreateAccountPage?: boolean,
    className?: string,
}
export const PHONE_NUMBER_MASK = [
  "(",
  /[2-9]/,
  /\d/,
  /\d/,
  ")",
  " ",
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];
const PhoneNumberForm : React.FC <IPhoneNumberForm> = ({ setPhoneNumber, isCreateAccountPage = false, className = ""}) => {
  const handlePhoneNumber = (phone : string) => {
      setPhoneNumber(phone)
  }
  return (
      <div>
        {
          !isCreateAccountPage &&
          <p className="f-w5 font-rale f-s16 clr-dark-red text-start pt-md-2 mb-1">
          Please add a contact number below!
        </p>
        }
        <Formik
            initialValues={{ phone: "" }}
            validationSchema={personalInfoSchema}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(false);
            }}
        >
          {({
              errors,
              touched,
              setFieldValue,
              handleSubmit,
            }) => (
              <form
                  className="new_form_design profile-info-read-only-field"
                  onSubmit={handleSubmit}
              >
                <Row>
                  <Col sm={12} md={12} className="mb-3 mb-md-0">
                    <label htmlFor="phone" className="d-flex justify-content-between align-items-center">
                      Phone Number
                      {isCreateAccountPage ? 
                        <span className="f-s14 text-transform-none font-rale f-w5 ls-normal">

                        </span> : null 
                      }
                      </label>
                    <Field
                        name="phone"
                        render={({ field }) => (
                            <MaskedInput
                                errors={errors}
                                {...field}
                                mask={PHONE_NUMBER_MASK}
                                id="phone-number"
                                placeholder="(223) 123-1234"
                                type="text"
                                onBlur={(e) => {handlePhoneNumber(e?.target?.value?.replace(/[() -]/g, ""))}}
                                // value={values.phone.replace(/[() -]/g, "")}
                                onChange={(e) =>
                                {
                                  setFieldValue(
                                      "phone",
                                      e.target.value.replace(/[() -]/g, "")
                                  )
                                }
                                }
                                guide={true}
                                className={`
                                ${className}
                          ${
                                    errors.phone && touched.phone
                                        ? "input_error"
                                        : "text-input"
                                }
                            form-control`}
                            />
                        )}
                    />

                    {errors.phone && touched.phone && (
                        <div className="input-feedback clr-dark-red f-s14 font-rale mt-1 d-inline-block">
                          {errors.phone}
                        </div>
                    )}
                  </Col>
                </Row>
              </form>
          )}
        </Formik>
      </div>
  )
}
export default PhoneNumberForm;