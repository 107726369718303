import React from 'react';
import Button from '../Buttons/Button';
import { Toast_Func } from '../../helpers/toast.helper';
import useLookUpGiftCard from '../../react-query-hooks/useLookUpGiftCard';
import { giftCardLookupSchema } from '../../validationSchemas/giftCardSchema';
import { FormField } from '../FormFields/FormField';
import { Formik } from 'formik';

interface IGiftCardForm {
  handleCloseGiftCard?: () => void;
  setOpenAddLookUpGiftCard?: (val: boolean) => void;
  setBalance?: (val: string) => void;
  setGiftCardNo: (val: string) => void;
  giftCardNo: string;
}

const GiftCardLookUpForm = (props: IGiftCardForm) => {
  const {
    handleCloseGiftCard,
    setOpenAddLookUpGiftCard,
    setBalance,
    setGiftCardNo,
    giftCardNo,
  } = props;

  const { isFetching, refetch: lookUpGiftCard } = useLookUpGiftCard(
    giftCardNo?.trim()
  );

  const initialFormState = {
    name: '',
    gift_card_no: '',
  };

  const checkGiftCard = async () => {
    const giftCardData = await lookUpGiftCard();
    if (giftCardData.data.status) {
      setOpenAddLookUpGiftCard(true);
      setBalance?.(giftCardData.data?.data?.balance || '0');
      handleCloseGiftCard?.();
      return;
    }
    if (!giftCardData?.data?.status) {
      Toast_Func({ status: false, message: giftCardData?.data?.message });
      return;
    }
  };

  return (
    <>
      <Formik
        initialValues={initialFormState}
        validationSchema={giftCardLookupSchema}
        onSubmit={checkGiftCard}
      >
        {({
          values,
          errors,
          touched,
          submitForm,
          setFieldValue
        }) => (
          <>
            <div
              className={`new_form_design my-0 mx-auto delivery_location_select full_width_mob form-group`}
            >
              <FormField
                labelText={'GIFT CARD NUMBER'}
                name="gift_card_no"
                type="text"
                onChange={(e) => {
                  setGiftCardNo(e.target.value);
                  setFieldValue("gift_card_no", e.target.value);
                }}
                errors={errors}
                touched={touched}
                value={values.gift_card_no}
              />
            </div>
            <div className="d-flex justify-content-center my-5">
              <Button
                type="button"
                className="btn btn-large py-2 f-s16 w-100"
                id={'add-gift-card-button'}
                onClick={submitForm}
                loading={isFetching}
                disabled={isFetching}
              >
                CHECK BALANCE
              </Button>
            </div>
          </>
        )}
      </Formik>
    </>
  );
};
export default GiftCardLookUpForm;
