import React, { useState } from "react";
import { GUEST_USER } from "../../constants";
import { getUser } from "../../helpers/helperMethods";

interface ICheckbox {
    setIsMessageSubscription: (boolean) => void;
    isCreateAccountPage?: boolean;
    color?:string
}

const SmsCheckbox: React.FC <ICheckbox> = ({setIsMessageSubscription, isCreateAccountPage = false, color}) => {
    const [isChecked, setIsChecked] = useState(false);
    const [isNotificationChecked, setIsNotificationChecked] = useState(true);

    const user = getUser();
  return (
    <>
      {
        isCreateAccountPage ?
        <div className="form-group theme_check_boxes margin-0">
          <input
            name="notification_subscription"
            type="checkbox"
            id="checkbox_2"
            onChange={(e) => {
              setIsNotificationChecked(!isNotificationChecked);
            //   handleChange(e);
            }}
            checked={isNotificationChecked}
          />
          <label htmlFor="checkbox_2" className={`text-start d-flex align-items-start pt-3 ${color}`}>
              <span className="box_wrap"></span>
              Yes, I want to receive account notification text messages at the number provided
          </label>
        </div>
      : null
      }
      {isCreateAccountPage || (user.type !== GUEST_USER && !user.subscribe_to_sms_marketing) ? (
        <div className={`form-group theme_check_boxes ${isCreateAccountPage && "margin-0"}`}>
          <input
            name="marketing_email_subscription"
            type="checkbox"
            id="checkbox_1"
            onChange={(e) => {
              setIsChecked(!isChecked);
              setIsMessageSubscription(!isChecked);
            //   handleChange(e);
            }}
            checked={isChecked}
          />
          {
              isCreateAccountPage ?
              <label htmlFor="checkbox_1" className={`text-start d-flex align-items-start pt-3 ${color}`}>
                <span className="box_wrap"></span>
                  Yes, I want to receive the marketing text messages at the number provided
              </label>
            : 
              <label htmlFor="checkbox_1" className={`text-start d-flex align-items-start pt-3 ${color}`}>
                <span className="box_wrap"></span>
                Earn free food and be the first to know about everything new at Café Zupas! 
                By submitting your phone number, you agree to receive communication from Café Zupas.
              </label>
            }
        </div>
      ) : null}
    </>
  );
};

export default SmsCheckbox;
