import { useQuery } from "react-query";
import { customerBusinessService } from "../services";

const lookUpGiftCard = async (newGiftCard: string) => {
  try {
    const response = await customerBusinessService.lookUpGiftCard(newGiftCard);
    return response?.data;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    } else {
      return error.data;
    }
  }
};

export default function useLookUpGiftCard(newGiftCard?: string, ) {
  return useQuery<any>(["giftCardLookup"], () => lookUpGiftCard(newGiftCard), {
    enabled: false,
  });
}
