import { AxiosResponse, AxiosError, AxiosRequestConfig } from 'axios'
import { CURRENT_USER } from '../constants'
import { unauthorizeLogout } from '../context/UserAuthentication/actions';
import { getRequestId, getUser } from '../helpers/helperMethods';
import store from '../redux/store/store';
import { bugSnagNotifyAPIError } from '../helpers/bugSnagHelper';

const {dispatch} = store;


export const requestHandler = (request: AxiosRequestConfig) => {
    let authInfo = getUser();
    let requestId = getRequestId();
    if (authInfo.token) {
        request.headers['Authorization'] = request.headers['Authorization'] || 'Bearer ' + authInfo.token;
        request.headers['token'] = request.headers['token'] || authInfo.authentication_token;
        request.headers['request-id'] = request.headers['request-id'] || requestId;
        request.headers['Refresh-Token'] = request.headers['Refresh-token'] || "";
    }
    return request;
}

export const successResponseHandler = (response: AxiosResponse) => {
    return response;
}

export const errorResponseHandler = (error: AxiosError) => {
    let authInfo = getUser();
    if(error?.response?.status === 401 && authInfo.token){
        unauthorizeLogout(dispatch);
        bugSnagNotifyAPIError("Authentication Error", error?.response, `Request failed with status code 401`);
      }
      // Handles Server Errors for Bugsnag reporting
      if (error?.response?.status >= 500 && error?.response?.status < 600) {
        bugSnagNotifyAPIError("Server Error occurred", error?.response, `Server Error with status code ${error?.response?.status}`);
      }
      // Handles Network Errors for Bugsnag reporting
      if (!error?.response) {
        bugSnagNotifyAPIError("Network error occurred", { "Error": "Network failed" }, `Network Error - ${error?.message}`);
      }
    return Promise.reject(error)
}
