import * as Yup from "yup";
import {bool} from "yup";

export const giftCardSchema = (isGuestUser: boolean) => {
 return Yup.object().shape({
     gift_card_no: Yup.string()
     .matches(/^\d*$/, "Only numbers are allowed")
         .required("Gift card number is required."),
     ...(!isGuestUser && {
         name: Yup.string()
             .required("Gift card name is required.")
     })
 });
}

export const giftCardLookupSchema = () => {
    return Yup.object().shape({
        gift_card_no: Yup.string()
        .matches(/^\d*$/, "Only numbers are allowed")
            .required("Gift card number is required."),
    });
   }
