import React from "react";
import {
    ROUTE_HOME,
    ROUTE_OUR_KITCHEN,
    ROUTE_CATERING,
    ROUTE_FUNDRAISING,
    ROUTE_LOCATIONS,
    ROUTE_CAREERS,
    ROUTE_TALK_TO_US,
    ROUTE_MENU,
    TERMS_ROUTE_HTML,
    TERMS_ROUTE,
    PRIVACY_POLICY_ROUTE,
    ROUTE_LOGIN,
    ROUTE_CREATE_ACCOUNT,
    ROUTE_FAQ,
    ROUTE_FORGOT_PASSWORD,
    ROUTE_CAREERS_VALUES,
    ROUTE_TRAVEL_ABROAD,
    ROUTE_HOSPITALITY_TEAM,
    ROUTE_CULINARY_TEAM,
    ROUTE_LEADERSHIP_TEAM,
    ROUTE_CATERING_TEAM,
    ROUTE_GOOD_LIFE_LANE,
} from "../../constants";
/**
 * TODO: Add meta tags for each page for SEO optimization
 * "KEY NAME"] KEY NAME should match the exact pathname
 */
export let META_TAGS: { [key: string]: JSX.Element[] } = {
    "/nutrition":[<title>Café Zupas Nutrition Facts 2019 | Café Zupas</title>],
    "/menu/Protein Bowls": [ <meta name="description" content=" Fuel your day with our house-made protein bowls. Packed with fresh ingredients and bold flavors, each bowl is crafted to be the tastiest way to hit your protein goals." />],
    "/menu/Build Your Bowl":[ <meta name="description" content="Show off your chef skills and get exactly what you're craving when you build your own protein bowl or create a custom salad at Cafe Zupas."/>],
    "/menu/Chef's Secret Menu":[ <meta name="description" content="Unlock all Cafe Zupas has to offer with a look at our not-so-secret menu! "/>],
    "/menu/Desserts":[ <meta name="description" content="The Café Zupas dessert menu is sure to have the perfect little treat to satisfy your sweet tooth. And let's be real, you always deserve a little treat. "/>],
    "/menu/Drinks":[ <meta name="description" content="Thirsty? Café Zupas drink bar has you covered with everything from house-made fruit infusions to all your fizzy favorites. Find the perfect pair for your meal! "/>],
    "/menu/Group Trays":[ <meta name="description" content="Share the love with Café Zupas Group Trays! Perfect for groups, our trays feature pre-portioned soups and sandwiches and party-style salads and desserts made for sharing. "/>],
    "/menu/Kid's Combo":[ <meta name="description" content="Healthy, tasty meals designed for kids with fun flavors and fresh ingredients! Choose from a range of options like made-from-scratch soups, sandwiches and fresh fruits. "/>],
    "/menu/Salads":[ <meta name="description" content="Café Zupas’ salads are loaded with crisp ingredients, packed with protein and topped with house-made dressings. Order online for pickup or delivery! "/>],
    "/menu/Sandwiches":[ <meta name="description" content="Made with fresh ingredients, bold flavors, and topped with house-made spreads, sandwiches and paninis at Café Zupas hit different. Order online now! "/>],
    "/menu/Seasonal":[ <meta name="description" content="Discover timely flavors with Café Zupas' seasonal menus! From hearty fall soups to fresh summer salads, our selection of rotating favorites always has something new to look forward to. "/>],
    "/menu/Sides":[ <meta name="description" content="Upgrade your meal with Café Zupas' cravable sides! "/>],
    "/menu/Soups":[ <meta name="description" content="Enjoy Café Zupas' delicious soups with made-from-scratch ingredients. Savor our variety of flavors, perfect for any craving. Order a bowl online today! "/>],
    "/menu/Try 2 Combo":[ <meta name="description" content="Mix and match your Café Zupas favorites with a Try 2 Combo! Choose two items from three of our most popular categories: Salads, Soups, and Sandwiches. "/>],
    "cateringMenu/Build Your Own":[ <meta name="description" content="Create a customized catering experience when you build your own protein bowl or create your own salad. "/>],
    "/menu/Build Your Own":[ <meta name="description" content="Customize your protein bowl or salad with your favorite toppings! Choose from our selection of crisp veggies, fresh fruits, hearty proteins and house-made dressings. "/>],
};

META_TAGS[ROUTE_OUR_KITCHEN] = [
                                <title>House-Made Happiness |Our Promise</title>,
                                <meta name="keywords" content="cafe ingredients"></meta>,
                                <meta name="description" 
                                content="Every dish at Café Zupas is made-from-scratch in our 
                                kitchens using only the best ingredients, 
                                where great flavor meets fresh food."/>
                            ];
META_TAGS[ROUTE_MENU] = [   
                            <title>Menu — Salads, Soups, Sandwiches, Protein Bowls — Café Zupas</title>,
                            <meta name="keywords" content="cafe zupas menu"></meta>,
                        ];
META_TAGS[ROUTE_LOCATIONS] = [  
                                <title>Find A Cafe Zupas | Healthy, Happy & House-Made Food</title>,
                                <meta name="keywords" content="zupas near me"></meta>,
                                <meta name="description" 
                                content="Find your local spot for crave-worthy salads,
                                 protein bowls, soups, and sandwiches served in a vibrant 
                                 setting where house-made happiness meets fresh flavor."/>
                            ];
META_TAGS[ROUTE_CAREERS] = [    
                                <title>Careers | Grow With Us</title>,
                                <meta name="keywords" content="zupas hiring"/>,
                                <meta name="description" 
                                content="Working at Cafe Zupas means opportunities for growth,
                                 reward, and a whole lot of fun. It’s time to Nourish Your Career!"/>
                            ];
META_TAGS[ROUTE_CATERING] = [   <title>Catering & Catering Delivery | Café Zupas</title>,
                                <meta name="keywords" content="zupas catering"></meta>,
                                <meta name="description" content=""/>
                            ];
META_TAGS[ROUTE_HOME] = [   <title>Café Zupas - Bowls, Soups, Salads, & Sandwiches + Catering</title>,
                            <meta name="keywords" content="soup and salad"></meta>,
                            <meta name="description" 
                            content="Protein bowls, soups, salads, sandwiches, and desserts house-made
                             from scratch and prepared fresh with over 200 high-quality ingredients."/>
                        ];
META_TAGS[ROUTE_FUNDRAISING] = [    <title>Fundraising | Eats That Earn</title>,
                                    <meta name="keywords" content="fundraiser events"></meta>,
                                    <meta name="description" content="Cafe Zupas fundraising program makes it as easy for schools, sports teams, 
                                    charities and more to earn money for their cause in a tasty way!"/>
                                ];
META_TAGS[ROUTE_TALK_TO_US] = [ <title>Contact Us | Cafe Zupas</title>,
                                <meta name="description" content="We want to hear from you! Contact Café Zupas for feedback,
                                questions, or support. "/>
                            ];
META_TAGS[TERMS_ROUTE_HTML] = [
                                <title>Terms & Conditions — Café Zupas</title>,
                                <meta name="keywords" content="Café Zupas Terms & Conditions"></meta>
                            ];
META_TAGS[TERMS_ROUTE] = [
                            <title>Terms & Conditions — Café Zupas</title>,
                            <meta name="keywords" content="Café Zupas Terms & Conditions"></meta>,
                            <meta name="description" content="Review Café Zupas' terms and conditions for using our website and services."/>
                        ];
META_TAGS[PRIVACY_POLICY_ROUTE] = [
                                    <title>Privacy Policy | Café Zupas</title>,
                                    <meta name="keywords" content="Café Zupas Privacy Policy"></meta>,
                                    <meta name="description" content="Learn how Café Zupas protects your privacy.
                                     Discover how we collect, use,
                                    and safeguard your personal information. Your trust matters!"/>
                                ];
META_TAGS[ROUTE_LOGIN] = [
                            <title>Login & Account Sign-Up | Café Zupas</title>,
                            <meta name="keywords" content="Café Zupas Login & Account Sign-Up"></meta>
                        ];
META_TAGS[ROUTE_CREATE_ACCOUNT] = [
                                    <title>Create A New Account | Café Zupas</title>,
                                    <meta name="keywords" content="Café Zupas Create A New Account"></meta>
                                ];
META_TAGS[ROUTE_FAQ] = [
                        <title>Join Rewards | Earn Free Food</title>,
                        <meta name="keywords" content="Café Zupas Loyalty Rewards"></meta>
                        ];
META_TAGS[ROUTE_FORGOT_PASSWORD] = [
                                    <title>Login Forgot Password | Café Zupas</title>,
                                    <meta name="keywords" content=" Café Zupas Forgot Password"></meta>
                                ]
META_TAGS[ROUTE_CAREERS_VALUES] = [<title>Careers | Team Values</title>];
META_TAGS[ROUTE_TRAVEL_ABROAD] = [<title>Careers | Work Here, See The World</title>];
META_TAGS[ROUTE_HOSPITALITY_TEAM] = [<title>Careers | Hospitality</title>];
META_TAGS[ROUTE_CULINARY_TEAM] = [<title>Careers | Culinary</title>];
META_TAGS[ROUTE_LEADERSHIP_TEAM] = [<title>Careers | Leadership</title>];
META_TAGS[ROUTE_CATERING_TEAM] = [<title>Careers | Catering</title>];
META_TAGS[ROUTE_GOOD_LIFE_LANE] = [<title>Mobile Order Drive-Thru | Cafe Zupas</title>]

